import React, {useState} from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner-v3';
import SearchForm from './section-components/search-form';
import Features from './section-components/features-v1';
import FeaturedItem from './section-components/featured-item-v1';
import Footer from './global-components/footer';
import Axios from "axios";

const Home = () => {
	const [banners, setBannerContent] = useState([]);
	const [services, setServices] = useState([]);
	const [contact, setContactInfos] = useState([]);
	const [property_types, setPropertyTypes] = useState([]);
	const [property_statuses, setPropertyStatuses] = useState([]);
	const [properties, setProperties] = useState([]);
	const [properties_data, setPropertiesData] = useState([]);
	const [property_amenities, setPropertyAmenities] = useState([]);
	const [property_owners, setPropertyOwners] = useState([]);
	const [images, setPropertyImages] = useState([]);
    const [areas, setAreas] = useState([]);
    const [status, setStatus] = useState([]);
    const [types, setTypes] = useState([]);
    React.useEffect(() => {
		getData();
	}, []);
	const getData = () => {
		Axios.get(window.api_url+'/api/global')
		.then(res => {
            const property=[];
            const properties_data=[];
            let areaArray=[];
            let statusArray=[];
            let typeArray=[];
            let response = res.data.data.global_data;
            const contact = response.contact;
            const banners = response.banner;
            const properties = response.properties;
            const property_types = response.property_types;
            const property_statuses = response.property_statuses;
            const property_amenities = response.property_amenities;
            const property_owners = response.property_owners;
            const images = response.images;
            const services = response.services;
            property_types.map(type=>{
                let obj={}
                obj.id=type.id;
                obj.name=type.name;
                typeArray.push(obj)
            });
            properties.map(pro=>{
                if(!property.includes(pro.listing_purpose)){
                    property.push(pro.listing_purpose)
                    properties_data.push(pro)
                }
                if(!areaArray.includes(pro.sub_district)){
                    areaArray.push(pro.sub_district)
                }
                if(!statusArray.includes(pro.listing_purpose)){
                    statusArray.push(pro.listing_purpose)
                }
            })
            setServices(services);
			setBannerContent(banners);
            setContactInfos(contact);
            setProperties(properties);
            setPropertyTypes(property_types);
            setPropertyStatuses(property_statuses);
            setPropertyAmenities(property_amenities);
            setPropertyOwners(property_owners);
            setPropertyImages(images);
            setPropertiesData(properties_data);
            setTypes(typeArray);
            setAreas(areaArray);
            setStatus(statusArray);
		});
	}

    return <>
        <Navbar contact_infos={contact} property_types={property_types} properties_data={properties_data}/>
        <Banner bannerContents={banners}/>
        <SearchForm properties={properties} property_types={property_types} areas={areas} status={status} types={types}/>
        <Features customClass="ltn__feature-area section-bg-1--- pt-115 pb-90 mb-120---" services={services}/>
        <FeaturedItem properties={properties} property_types={property_types} property_statuses={property_statuses} property_owners={property_owners} property_amenities={property_amenities} images={images} contact={contact}/>
        <Footer property_types={property_types} properties_data={properties_data} contact_infos={contact}/>
    </>
}

export default Home

