import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends Component {
    render() {
		let contact = this.props.contact_infos;
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
		<div>
			<header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu">
			<div className="ltn__header-top-area section-bg-6 top-area-color-white---">
				<div className="container">
					<div className="row">
						<div className="col-md-9">
							<div className="ltn__top-bar-menu">
								<ul>
									<li><a href={`mailto:${contact.email1}?Subject=Mail%20From%20Alluviumbd`}><i className="icon-mail" />{contact.email1? contact.email1:"info@example.com"}</a></li>
									<li><a href="#"><i className="icon-placeholder" />{contact.full_address ? contact.full_address:"example, example-1234"}</a></li>
								</ul>
							</div>
						</div>
						<div className="col-md-3">
							<div className="top-bar-right text-end">
								<div className="ltn__top-bar-menu">
									<ul>
										<li>
											<div className="ltn__social-media">
												<ul>
													<li><a href={`${contact.facebook_link?contact.facebook_link:"https://www.facebook.com"}`} target="_blank" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
													<li><a href={`${contact.twitter_link?contact.twitter_link:"https://www.twitter.com"}`} target="_blank" title="Twitter"><i className="fab fa-twitter" /></a></li>
													<li><a href={`${contact.instagram_link?contact.instagram_link:"https://www.instagram.com"}`} target="_blank" title="Instagram"><i className="fab fa-instagram" /></a></li>
													<li><a href={`${contact.linkedin_link?contact.linkedin_link:"https://www.linkedin.com"}`} target="_blank" title="Lindedin"><i className="fab fa-linkedin" /></a></li>
												</ul>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white" style={{paddingTop: 10, paddingBottom:10}}>
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="site-logo-wrap">
								<div className="site-logo go-top">
								<Link to="/"><img src={publicUrl+"assets/img/logo.png"} alt="Logo" /></Link>
								</div>
								<div className="get-support clearfix d-none">
								<div className="get-support-icon">
									<i className="icon-call" />
								</div>
								<div className="get-support-info">
									<h6>Get Support</h6>
									<h4><a href="tel:+01322902980">123-456-789-10</a></h4>
								</div>
								</div>
							</div>
						</div>
						<div className="col header-menu-column">
						<div className="header-menu d-none d-xl-block">
							<nav>
							<div className="ltn__main-menu go-top">
								<ul>
								<li>
									<Link to="/">Home</Link>
								</li>
								<li className="menu-icon"><Link to="/about">About</Link>
									<ul>
										<li><Link to="/about">About</Link></li>
										<li><Link to="/faq">FAQ</Link></li>
									</ul>
								</li>
								{
									this.props.properties_data?.map((property, index) =>(
										<li className="menu-icon" key={index}>
											<Link 
												to={{
														pathname: '/listings',
														search: `?purpose=${property.listing_purpose.split(" ")[0].toLowerCase()+'-'+property.listing_purpose.split(" ")[1].toLowerCase()}`
													}}
											>{property.listing_purpose==='For Sale'?'Buy':property.listing_purpose==='For Rent'?'Rent':'Lease'}
											</Link>
											<ul>
												<li>
													<Link 
														to={{
															pathname: '/listings',
															search: `?purpose=${property.listing_purpose.split(" ")[0].toLowerCase()+'-'+property.listing_purpose.split(" ")[1].toLowerCase()}&property-type=${property.property_type_id}`
														}}
													>	{property.property_type+" "+property.listing_purpose}
													</Link>
												</li>
											</ul>
										</li>
									))
								}
								<li><Link to="/contact">Contact</Link></li>
								</ul>
							</div>
							</nav>
						</div>
					</div>
					<div className="col ltn__header-options ltn__header-options-2">
					{/* mini-cart */}
					{/* Mobile Menu Button */}
					<div className="mobile-menu-toggle d-xl-none">
						<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
						<svg viewBox="0 0 800 600">
							<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
							<path d="M300,320 L540,320" id="middle" />
							<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
						</svg>
						</a>
					</div>
					</div>
				</div>
				</div>
			</div>
			</header>
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					<div className="site-logo">
						<Link to="/"><img src={publicUrl+"assets/img/logo.png"} alt="Logo" /></Link>
					</div>
					<button className="ltn__utilize-close">×</button>
					</div>
					<div className="ltn__utilize-menu">
					<ul>
						<li>
							<a href="#">Home</a>
						</li>
						<li><Link to="/about">About</Link>
						<ul className="sub-menu">
							<li><Link to="/about">About</Link></li>
							<li><Link to="/faq">FAQ</Link></li>
						</ul>
						</li>
						{
							this.props.properties_data?.map((property, index) =>(
								<li key={index}>
									<span className='menu-expand'/>
									<Link 
										to={{
											pathname: '/listings',
											search: `?purpose=${property.listing_purpose.split(" ")[0].toLowerCase()+'-'+property.listing_purpose.split(" ")[1].toLowerCase()}`
										}}
									>
										{property.listing_purpose==='For Sale'?'Buy':property.listing_purpose==='For Rent'?'Rent':'Lease'}
									</Link>
									<ul className="sub-menu">
										<li>
											<Link 
												to={{
													pathname: '/listings',
													search: `?purpose=${property.listing_purpose.split(" ")[0].toLowerCase()+'-'+property.listing_purpose.split(" ")[1].toLowerCase()}&property-type=${property.property_type_id}`
												}}
											>	{property.property_type+" "+property.listing_purpose}
											</Link>
										</li>
										
									</ul>
								</li>
							))
						}
						<li><Link to="/contact">Contact</Link></li>
					</ul>
					</div>
					<div className="ltn__social-media-2">
						<ul>
							<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
							<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
							<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
        )
    }
}

export default Navbar