import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<a className="slick-next slick-arrow" onClick={onClick}>
			<i className="fas fa-arrow-right" alt="Arrow Icon"/>
		</a>
	);
}
  
function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<a className="slick-prev slick-arrow" onClick={onClick}>
			<i className="fas fa-arrow-left"/>
		</a>
	);
}

class FeaturedItemV1 extends Component {
    render() {
		const settings = {
			infinite: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			pauseOnHover: true,
			nextArrow: <SampleNextArrow />,
      		prevArrow: <SamplePrevArrow />,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: true
					}
				},
				{
					breakpoint: 600,
					settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
					slidesToShow: 1,
					slidesToScroll: 1
					}
				}
			]
		};

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div>
			<div className="ltn__product-slider-area ltn__product-gutter pt-20 pb-90">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h1 className="section-title">Featured Listings</h1>
			        </div>
			      </div>
			    </div>
				<Slider {...settings} className="row ltn__product-slider-item-three-active slick-arrow-1">
				  {
						this.props.properties?.map((property, index) =>(
							<div className="col-lg-12" key={index}>
								<div className="ltn__product-item ltn__product-item-4 text-center---">
									<div className="product-img go-top">
										<Link to={{ pathname: '/listing-details/'+property.id }}>
											<img src={property.small_image?window.api_url+"/upload_files/property/"+property.small_image:publicUrl+"assets/img/product-3/1.jpg"} alt="#" />
										</Link>
										<div className="product-badge">
											<ul>
												<li style={{marginRight: 5}} className={property.listing_purpose==='For Sale'?'sale-badge bg-info':property.listing_purpose==='For Rent'?'sale-badge bg-blue':'sale-badge bg-purple'}>{property.listing_purpose}</li>
												<li className={property.property_status==='Available'?'sale-badge bg-green':'sale-badge bg-danger'}>{property.property_status}</li>
											</ul>
										</div>
										<div className="product-img-location-gallery">
											<div className="product-img-location go-top">
												<ul>
													<li>
														<Link to="/contact"><i className="flaticon-pin" /> {property.sub_district+", "+property.district}</Link>
													</li>
												</ul>
											</div>
											<div className="product-img-gallery go-top">
												<ul>
													<li>
														<Link to={{ pathname: '/listing-details/'+property.id }}>
															<i className="fas fa-camera" />
															{
																" "+property.images.filter((x) => { return x.property_id === property.id; }).length
															}
														</Link>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="product-info">
										{/* <div className="product-price">
										<span>$34,900<label>/Month</label></span>
										</div> */}
										<h2 className="product-title go-top">
											<Link to={{ pathname: '/listing-details/'+property.id }}> 			
												{property.title}
											</Link>
											</h2>
										<div className="product-description">
											<p>{property.property_type}</p>
										</div>
										<ul className="ltn__list-item-2 ltn__list-item-2-before">
										<li><span>{!property.rooms?"N/A":property.rooms} <i className="flaticon-bed" /></span>
											Bedrooms
										</li>
										<li><span>{!property.baths?"N/A":property.baths} <i className="flaticon-clean" /></span>
											Bathrooms
										</li>
										<li><span>{property.area} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
											{property.unit}
										</li>
										</ul>
									</div>
									<div className="product-info-bottom">
										<div className="real-estate-agent go-top">
										<div className="agent-img">
											<img
												src={publicUrl + "assets/img/alluvium_logo.jpeg"}
												alt="Image"/>
										</div>
										<div className="agent-brief">
											<h6>Alluvium Landmark Ltd</h6>
											<small>+88{this.props.contact.phone1? this.props.contact.phone1:"01322902980"}</small>
										</div>
										</div>
										<div className="product-hover-action">
											<ul>
												<li>
												<a href={this.props.contact.phone1? "tel:+88"+this.props.contact.phone1:"tel:+8801322902980"} title="Call to us">
													<i className="flaticon-phone-call" /></a>
												</li>
												<li>
												<Link to={{ pathname: '/listing-details/'+property.id }}>
														<i className="flaticon-add" />
												</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						))
					}
				</Slider>
			  </div>
			</div>
		   </div>
        }
}

export default FeaturedItemV1