import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Axios from "axios";

const ShopDetails = (param) => {
	const monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	let publicUrl = process.env.PUBLIC_URL+'/'
	const [disabled, setDisabled] = useState('');
	const [textColor, setTextColor] = useState('');
	const [alert, setAlert] = useState('');
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');
	let property = param.property;
	let contact = param.contact_infos;

	const sendMessage = () => {
		setLoading(true)
		setDisabled("disabled");
		if(name=="" || email=="" || phone==""){
			setTextColor('text-warning')
			setAlert("Please fill all the fields");
			setDisabled("")
			setLoading(false)
		}
		else {
			Axios.get(window.api_url+`/api/send-mail?listing_id=${param.listingId}&name=${name}&email=${email}&phone=${phone}&message=${message}`)
			.then(res => {
				if(res.data.success==true){
					setDisabled("")
					setLoading(false)
					setTextColor('text-success')
					setAlert("Message has been send successfully!")
					setName("");
					setEmail("");
					setMessage("");
					setPhone("");
				}
				else {
					setLoading(false)
					setDisabled("")
					setTextColor('text-danger')
					setAlert("Message not sent. Something went wrong!")
					setName("");
					setEmail("");
					setMessage("");
					setPhone("");
				}
			}).catch(e=>{
				setLoading(false)
				setDisabled("")
				setTextColor('text-danger')
				setAlert("Message not sent. Something went wrong!")
				setName("");
				setEmail("");
				setMessage("");
				setPhone("");
			});
		}
	}
	setTimeout(() => {
		setTextColor('alert-hidden')
	}, 2000);

	return (
		<>
			<div className="ltn__shop-details-area pb-10">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-12">
							<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
								<div className="ltn__blog-meta">
									<ul>
										<li className="ltn__blog-category">
											<a className="bg-danger" href={void(0)}>{property.property_type}</a>
										</li>
										<li className="ltn__blog-category">
											<a className="bg-orange" href={void(0)}>{property.listing_purpose}</a>
										</li>
										<li className="ltn__blog-date">
											<i className="far fa-calendar-alt"/>{new Date(property.date).getDate() + " " + monthNames[new Date(property.date).getMonth()] + " " + new Date(property.date).getFullYear()}
										</li>
										<li>
											{/* <Link to="#"><i className="far fa-comments" />35 Comments</Link> */}
										</li>
									</ul>
								</div>
								<h1>{property.title}</h1>
								<label><span className="ltn__secondary-color"><i
									className="flaticon-pin"/></span> {property.location}</label>
								<h4 className="title-2">Description</h4>
								<p dangerouslySetInnerHTML={{__html: property.description}}/>
								<h4 className="title-2">Listing Detail</h4>
								<div className="property-detail-info-list section-bg-1 clearfix mb-60">
									<ul>
										<li><label>Property ID:</label> <span>{property.listing_id}</span></li>
										<li><label>Area: </label> <span>{property.area+" "+property.unit}</span></li>
										<li><label>Rooms:</label>
											<span>{!property.rooms ? "N/A" : property.rooms}</span></li>
										<li><label>Baths:</label>
											<span>{!property.baths ? "N/A" : property.baths}</span></li>
										<li><label>Published:</label>
											<span>{monthNames[new Date(property.date).getMonth()] + " " + new Date(property.date).getFullYear()}</span>
										</li>
									</ul>
									<ul>
										<li><label>Lot Area:</label> <span>{property.area+" "+property.unit} </span></li>
										<li><label>Beds:</label>
											<span>{!property.beds ? "N/A" : property.beds}</span></li>
										<li><label>Price:</label><span>Call or email for price</span></li>
										<li><label>Property Status:</label> <span className={property.property_status=='Available'?'text-white bg-success p-2 rounded-pill':'text-white bg-danger p-2 rounded-pill'}>{property.property_status}</span>
										</li>
									</ul>
								</div>
								<h4 className="title-2">Amenities</h4>
								<div className="property-detail-feature-list clearfix mb-45">
									<ul>
										
											{
												property.amenities?.map((amn, ind) => (
													<li key={ind}>
														<div className="property-detail-feature-list-item" style={{verticalAlign: 'center'}}>
															<i className="fa fa-check-square fa-2x"/>{amn.name}
														</div>
													</li>
												))
											}
										

									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
								{/* Author Widget */}
								<div className="widget ltn__author-widget">
									<h4 className="ltn__widget-title ltn__widget-title-border-2 mb-4"
										style={{margin: "auto"}}>Contact Information</h4>
									<div className="ltn__author-widget-inner text-center">
										<img
											src={publicUrl + "assets/img/alluvium_logo.jpeg"}
											alt="Image"/>
										<h5>Alluvium Landmark Ltd.</h5>
										<small>{contact.full_address}</small>
										{/*<p>{owner.comment}</p>*/}
										<div className="ltn__social-media">
											<ul>
												<li style={{display:"flex", alignItem:"center"}}>
													<i className="fa fa-phone-square fa-2x mr-2"/>
													<a href={"tel: +88" + contact.phone1} style={{marginLeft:'10px'}} title="Phone"> {"+88" + contact.phone1}
												</a></li>
												<li style={{display:"flex", alignItem:"center"}}>
													<i className="fa fa-envelope-square fa-2x mr-2"/>
													<a href={"mailto:" + contact.email1} style={{marginLeft:'10px'}} title="Email"> {contact.email1}
												</a></li>
											</ul>
										</div>
									</div>
								</div>
								{/* Form Widget */}
								<div className="widget ltn__form-widget">
									<h4 className="ltn__widget-title ltn__widget-title-border-2">Drop Message For
										Any Query About This Property</h4>
										<input type="text"
												value={name}
												name="name"
												placeholder="Enter your name"
												onChange={(e)=>{setName(e.target.value)}}
										/>
										<input type="email"
												name="email"
												value={email}
												placeholder="Enter email address"
												onChange={(e)=>{setEmail(e.target.value)}}
										/>
										<input type="text"
												name="phone"
												value={phone}
												placeholder="Enter phone number"
												onChange={(e)=>{setPhone(e.target.value)}}
										/>
										<textarea name="message"
													placeholder="Enter message"
													value={'Property ID: '+property.listing_id+'\nTitle: '+property.title+'\n'+message}
													onChange={(e)=>{setMessage(e.target.value)}}
										/>
										<button className={"btn theme-btn-1 btn-effect-1 text-uppercase "+disabled}
												type="button" onClick={sendMessage}>
											{loading?(
												<div className="spinner-border" role="status">
													<span className="sr-only"/>
												</div>
											):"Send Message"}
										</button>
										<p style={{fontSize: 18, fontWeight: 'bold'}} className={"form-messege mb-0 mt-20 "+textColor}>{alert}</p>
								</div>
							</aside>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ShopDetails;