import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TeamV1 extends Component {
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let team = this.props.teams;

    return <div className="ltn__team-area pt-115 pb-90 go-top">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title-area ltn__section-title-2--- text-center">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Team</h6>
								<h1 className="section-title">Our Team</h1>
							</div>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-4 col-sm-6">
							<div className="ltn__team-item ltn__team-item-3---">
								<div className="team-img">
								<img src={window.api_url+"/"+team.image1} alt="Image" />
								</div>
								<div className="team-info">
								<h4><Link to="#">{team.name1}</Link></h4>
								<h6 className="ltn__secondary-color">{team.designation1}</h6>
								<div className="ltn__social-media">
									<ul>
									<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
									<li><a href="#"><i className="fab fa-twitter" /></a></li>
									<li><a href="#"><i className="fab fa-linkedin" /></a></li>
									</ul>
								</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6">
							<div className="ltn__team-item ltn__team-item-3---">
								<div className="team-img">
								<img src={window.api_url+"/"+team.image2} alt="Image" />
								</div>
								<div className="team-info">
								<h4><Link to="/#">{team.name2}</Link></h4>
								<h6 className="ltn__secondary-color">{team.designation2}</h6>
								<div className="ltn__social-media">
									<ul>
									<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
									<li><a href="#"><i className="fab fa-twitter" /></a></li>
									<li><a href="#"><i className="fab fa-linkedin" /></a></li>
									</ul>
								</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6">
							<div className="ltn__team-item ltn__team-item-3---">
								<div className="team-img">
								<img src={window.api_url+"/"+team.image3} alt="Image" />
								</div>
								<div className="team-info">
								<h4><Link to="#">{team.name3}</Link></h4>
								<h6 className="ltn__secondary-color">{team.designation3}</h6>
								<div className="ltn__social-media">
									<ul>
									<li><a href="#"><i className="fab fa-facebook-f" /></a></li>
									<li><a href="#"><i className="fab fa-twitter" /></a></li>
									<li><a href="#"><i className="fab fa-linkedin" /></a></li>
									</ul>
								</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        }
}

export default TeamV1