import React, {useState} from 'react';
import {Link} from "react-router-dom";

const BannerV3 = (param) => {
	let banner = param.bannerContents;
	let banner1 = window.api_url+"/"+banner.image1;
	let banner2 = window.api_url+"/"+banner.image2;
	let banner3 = window.api_url+"/"+banner.image3;
	return (
		<>
			<div className="ltn__slider-area ltn__slider-3  section-bg-2">
				<div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
					<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60" style={{ backgroundImage: `url(${banner1})` }}>
						<div className="ltn__slide-item-inner text-center">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 align-self-center">
										<div className="slide-item-info">
											<div className="slide-item-info-inner ltn__slide-animation">
												<div className="slide-video mb-50 d-none">
												</div>
												<h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Real Estate Agency</h6>
												<h1 className="slide-title animated ">{banner.heading1? banner.heading1:"example heading"}</h1>
												<div className="slide-brief animated">
													<p>{banner.description1? banner.description1:"example description"}</p>
												</div>
												<div className="btn-wrapper animated go-top">
													<Link to="/contact" className="theme-btn-1 btn btn-effect-1">Make An Enquiry</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* ltn__slide-item */}
					<div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60" style={{ backgroundImage: `url(${banner2})` }}>
						<div className="ltn__slide-item-inner  text-right text-end">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 align-self-center">
										<div className="slide-item-info">
											<div className="slide-item-info-inner ltn__slide-animation">
												<h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Real Estate Agency</h6>
												<h1 className="slide-title animated ">{banner.heading2? banner.heading2:"example heading"}</h1>
												<div className="slide-brief animated">
													<p>{banner.description2? banner.description2:"example description"}</p>
												</div>
												<div className="btn-wrapper animated go-top">
													<Link to="/shop" className="theme-btn-1 btn btn-effect-1">Make An Enquiry</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* ltn__slide-item */}
					<div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60" style={{ backgroundImage: `url(${banner3})` }}>
						<div className="ltn__slide-item-inner  text-left">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 align-self-center">
										<div className="slide-item-info">
											<div className="slide-item-info-inner ltn__slide-animation">
												<div className="slide-video mb-50 d-none">
													{/* <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
														<i className="fa fa-play" />
													</a> */}
												</div>
												<h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Real Estate Agency</h6>
												<h1 className="slide-title animated ">{banner.heading3? banner.heading3:"example heading"}</h1>
												<div className="slide-brief animated">
													<p>{banner.description3? banner.description3:"example description"}</p>
												</div>
												<div className="btn-wrapper animated go-top">
													<Link to="/shop" className="theme-btn-1 btn btn-effect-1">Make An Enquiry</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BannerV3;