import React, {useState} from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Faq from './section-components/faq-v1';
import Footer from './global-components/footer';
import Axios from "axios";

const FaqV1 = () => {
	const [contact_infos, setContactInfos] = useState([]);
	const [property_types, setPropertyTypes] = useState([]);
	const [properties_data, setPropertiesData] = useState([]);
	const [faqs, setFAQData] = useState([]);

    React.useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		Axios.get(window.api_url+'/api/global')
		.then(res => {
            const property=[];
            const properties_data=[];
            let response = res.data.data.global_data;
            const contact_infos = response.contact;
            const properties = response.properties;
            const property_types = response.property_types;
            const faqs = response.faqs;
            properties.map(pro=>{
                if(!property.includes(pro.listing_purpose)){
                    property.push(pro.listing_purpose)
                    properties_data.push(pro)
                }
            })
            setContactInfos(contact_infos);
            setPropertyTypes(property_types);
            setPropertiesData(properties_data);
            setFAQData(faqs);
		});
	}

    return <div>
        <Navbar contact_infos={contact_infos} property_types={property_types} properties_data={properties_data}/>
        <PageHeader headertitle="Frequently asked questions" subheader="FAQ" />
        <Faq faqs={faqs} contact_infos={contact_infos}/>
        <Footer property_types={property_types} properties_data={properties_data} contact_infos={contact_infos}/>
    </div>
}

export default FaqV1

