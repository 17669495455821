import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FaqV1 extends Component {
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/';
        let contact = this.props.contact_infos;

    return <div className="ltn__faq-area mb-100">
            <div className="container">
            <div className="row">
                <div className="col-lg-8">
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {
                        this.props.faqs?.map((faq, index) =>(
                            <div className="card" key={index}>
                                <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target={`#faq-item-${index+1}`} aria-expanded="false">
                                {faq.question}
                                </h6>
                                <div id={`faq-item-${index+1}`} className="collapse" data-bs-parent="#accordion_2">
                                <div className="card-body">
                                    <p>{faq.answer}</p>
                                </div>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                    <div className="need-support text-center mt-100">
                        <h2>Still need help? Reach out to support 24/7:</h2>
                        <div className="btn-wrapper mb-30 go-top">
                            <Link to="/contact" className="theme-btn-1 btn">Contact Us</Link>
                        </div>
                        <h3><i className="fas fa-phone" /> +88{contact.phone1?contact.phone1:'01322902980'}</h3>
                    </div>
                </div>
                </div>
                <div className="col-lg-4">
                <aside className="sidebar-area ltn__right-sidebar">
                    {/* Banner Widget */}
                    <div className="widget ltn__banner-widget go-top">
                        <img src={publicUrl+"assets/img/banner/banner3.jpeg"} alt="Banner Image" />
                    </div>
                </aside>
                </div>
            </div>
            </div>
        </div>
  
        }
}

export default FaqV1