import React, {useState} from 'react';
import { Link } from 'react-router-dom';

const ShopGridV1 = (param) =>{
	const [properties, setProperties] = useState([]);
	let publicUrl = process.env.PUBLIC_URL+'/';
	const paramPurpose = param.purpose;
	const propertyTypeId = param.type;
	const area = param.area;
	const contact = param.contact;
	React.useEffect(() => {
        fetch(window.api_url+'/api/properties?purpose='+paramPurpose+'&propertyType='+propertyTypeId+'&area='+area)
            .then(results => results.json())
            .then(res => {
				const properties = res.data.properties;
                setProperties(properties);
            });
    }, [properties, paramPurpose, propertyTypeId]);

    return ( 
		<div>
			<div className="ltn__product-area ltn__product-gutter mb-100">
				<div className="container">
						<div className="row">
							<div className="col-lg-12">
							<div className="ltn__shop-options">
								<ul>
								<li>
									<div className="ltn__grid-list-tab-menu ">
									<div className="nav">
										<a className="active show" data-bs-toggle="tab" href="#liton_product_grid"><i className="fas fa-th-large" /></a>
										{/* <a data-bs-toggle="tab" href="#liton_product_list"><i className="fas fa-list" /></a> */}
									</div>
									</div>
								</li>
								{/*<li>*/}
								{/*	<div className="short-by text-center">*/}
								{/*	<select className="nice-select">*/}
								{/*		<option>Default sorting</option>*/}
								{/*		<option>Sort by popularity</option>*/}
								{/*		<option>Sort by new arrivals</option>*/}
								{/*		<option>Sort by price: low to high</option>*/}
								{/*		<option>Sort by price: high to low</option>*/}
								{/*	</select>*/}
								{/*	</div> */}
								{/*</li>*/}
								<li>
									<div className="showing-product-number text-right">
									<span>Showing {properties?properties.length:""} of {param.totalProperties.length} results</span>
									</div> 
								</li>
								</ul>
							</div>
							<div className="tab-content ">
								<div className="tab-pane fade active show" id="liton_product_grid">
									<div className="ltn__product-tab-content-inner ltn__product-grid-view">
										<div className="row">
										<div className="col-lg-12">
											{/* Search Widget */}
											<div className="ltn__search-widget mb-30">
											<form>
												<input type="text" placeholder="Search your keyword..." />
												<button type="button"><i className="fas fa-search" /></button>
											</form>
											</div>
										</div>
										{
											properties?.map((property, index) =>(
												<div className="col-lg-4 col-sm-6 col-12" key={index}>
													<div className="ltn__product-item ltn__product-item-4 text-center---">
														<div className="product-img go-top">
															<Link to={{ pathname: '/listing-details/'+property.id }}>
																<img src={property.small_image?window.api_url+"/upload_files/property/"+property.small_image:publicUrl+"assets/img/product-3/1.jpg"} alt="#" />
															</Link>
															<div className="product-badge">
																<ul>
																	<li style={{marginRight: 5}} className={property.listing_purpose==='For Sale'?'sale-badge bg-info':property.listing_purpose==='For Rent'?'sale-badge bg-blue':'sale-badge bg-purple'}>{property.listing_purpose}</li>
																	<li className={property.property_status==='Available'?'sale-badge bg-green':'sale-badge bg-danger'}>{property.property_status}</li>
																</ul>
															</div>
															<div className="product-img-location-gallery">
																<div className="product-img-location go-top">
																	<ul>
																		<li>
																			<Link to="/contact"><i className="flaticon-pin" /> {property.sub_district+", "+property.district}</Link>
																		</li>
																	</ul>
																</div>
																<div className="product-img-gallery go-top">
																	<ul>
																		<li>
																			<Link to={{ pathname: '/listing-details/'+property.id }}>
																				<i className="fas fa-camera" />
																				{
																					" "+property.images.filter((x) => { return x.property_id === property.id; }).length
																				}
																			</Link>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="product-info">
															{/* <div className="product-price">
															<span>$34,900<label>/Month</label></span>
															</div> */}
															<h2 className="product-title go-top">
																<Link to={{ pathname: '/listing-details/'+property.id }}> 			
																	{property.title}
																</Link>
																</h2>
															<div className="product-description">
																<p>{property.property_type}</p>
															</div>
															<ul className="ltn__list-item-2 ltn__list-item-2-before">
															<li><span>{!property.rooms?"N/A":property.rooms} <i className="flaticon-bed" /></span>
																Bedrooms
															</li>
															<li><span>{!property.baths?"N/A":property.baths} <i className="flaticon-clean" /></span>
																Bathrooms
															</li>
															<li><span>{property.area} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
																{property.unit}
															</li>
															</ul>
														</div>
														<div className="product-info-bottom">
															<div className="real-estate-agent go-top">
															<div className="agent-img">
																<img
																	src={publicUrl + "assets/img/alluvium_logo.jpeg"}
																	alt="Image"/>
															</div>
															<div className="agent-brief">
																<h6>Alluvium Landmark Ltd</h6>
																<small>+88{contact.phone1? contact.phone1:"01322902980"}</small>
															</div>
															</div>
															<div className="product-hover-action">
																<ul>
																	<li>
																	<a href={contact.phone1? "tel:+88"+contact.phone1:"tel:+8801322902980"} title="Call to us">
																		<i className="flaticon-phone-call" /></a>
																	</li>
																	<li>
																	<Link to={{ pathname: '/listing-details/'+property.id }}>
																			<i className="flaticon-add" />
																	</Link>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											))
										}
										{
											properties.length===0&&(
												<div className="ltn__404-area ltn__404-area-1 mb-120">
													<div className="container">
														<div className="row">
															<div className="col-lg-12">
																<div className="error-404-inner text-center">
																	<div className="error-img mb-30">
																		<img src={publicUrl+"assets/img/others/error-1.png"} alt="#" />
																	</div>
																	<h2>Result Not Found!!</h2>
																	<div className="btn-wrapper go-top">
																		<Link to="/" className="btn btn-transparent"><i className="fas fa-long-arrow-alt-left" /> BACK TO HOME</Link>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											)
										}
									</div>
								</div>
								</div>
							</div>
							{/* <div className="ltn__pagination-area text-center">
								<div className="ltn__pagination">
								<ul>
									<li><a href="#"><i className="fas fa-angle-double-left" /></a></li>
									<li><a href="#">1</a></li>
									<li className="active"><a href="#">2</a></li>
									<li><a href="#">3</a></li>
									<li><a href="#">...</a></li>
									<li><a href="#">10</a></li>
									<li><a href="#"><i className="fas fa-angle-double-right" /></a></li>
								</ul>
								</div>
							</div> */}
							</div>
						</div>
				</div>
			</div>
						
			<div className="ltn__modal-area ltn__add-to-cart-modal-area">
				<div className="modal fade" id="liton_wishlist_modal" tabIndex={-1}>
					<div className="modal-dialog modal-md" role="document">
					<div className="modal-content">
						<div className="modal-header">
						<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
						</button>
						</div>
						<div className="modal-body">
						<div className="ltn__quick-view-modal-inner">
							<div className="modal-product-item">
							<div className="row">
								<div className="col-12">
								<div className="modal-product-img">
									<img src={publicUrl+"assets/img/product/7.png"} alt="#" />
								</div>
								<div className="modal-product-info go-top">
									<h5><Link to="/product-details">Brake Conversion Kit</Link></h5>
									<p className="added-cart"><i className="fa fa-check-circle" />  Successfully added to your Wishlist</p>
									<div className="btn-wrapper">
									<Link to="/wishlist" className="theme-btn-1 btn btn-effect-1">View Wishlist</Link>
									</div>
								</div>
								{/* additional-info */}
								<div className="additional-info d-none">
									<p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
									<div className="payment-method">
									<img src={publicUrl+"assets/img/icons/payment.png"} alt="#" />
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
						</div>
					</div>
					</div>
				</div>
			</div>

			<div className="ltn__modal-area ltn__quick-view-modal-area">
				<div className="modal fade" id="quick_view_modal" tabIndex={-1}>
					<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
						<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
							{/* <i class="fas fa-times"></i> */}
						</button>
						</div>
						<div className="modal-body">
						<div className="ltn__quick-view-modal-inner">
							<div className="modal-product-item">
							<div className="row">
								<div className="col-lg-6 col-12">
								<div className="modal-product-img">
									<img src={publicUrl+"assets/img/product/4.png"} alt="#" />
								</div>
								</div>
								<div className="col-lg-6 col-12">
								<div className="modal-product-info">
									<div className="product-ratting">
									<ul>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star-half-alt" /></a></li>
										<li><a href="#"><i className="far fa-star" /></a></li>
										<li className="review-total"> <a href="#"> ( 95 Reviews )</a></li>
									</ul>
									</div>
									<h3>Brake Conversion Kit</h3>
									<div className="product-price">
									<span>$149.00</span>
									<del>$165.00</del>
									</div>
									<div className="modal-product-meta ltn__product-details-menu-1">
									<ul>
										<li>
										<strong>Categories:</strong> 
										<span className="go-top">
											<Link to="/blog">Parts</Link>
											<Link to="/blog">Car</Link>
											<Link to="/blog">Seat</Link>
											<Link to="/blog">Cover</Link>
										</span>
										</li>
									</ul>
									</div>
									<div className="ltn__product-details-menu-2">
									<ul>
										<li>
										<div className="cart-plus-minus">
											<input type="text" defaultValue="02" name="qtybutton" className="cart-plus-minus-box" />
										</div>
										</li>
										<li>
										<a href="#" className="theme-btn-1 btn btn-effect-1" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal">
											<i className="fas fa-shopping-cart" />
											<span>ADD TO CART</span>
										</a>
										</li>
									</ul>
									</div>
									<hr />
									<div className="ltn__social-media">
									<ul>
										<li>Share:</li>
										<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
										<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
										<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
										<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
									</ul>
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
						</div>
					</div>
					</div>
				</div>
			</div>

			<div className="ltn__modal-area ltn__add-to-cart-modal-area">
				<div className="modal fade" id="add_to_cart_modal" tabIndex={-1}>
					<div className="modal-dialog modal-md" role="document">
					<div className="modal-content">
						<div className="modal-header">
						<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
						</button>
						</div>
						<div className="modal-body">
						<div className="ltn__quick-view-modal-inner">
							<div className="modal-product-item">
							<div className="row">
								<div className="col-12">
								<div className="modal-product-img">
									<img src={publicUrl+"assets/img/product/1.png"} alt="#" />
								</div>
								<div className="modal-product-info go-top">
									<h5 className="go-top"><Link to="">Brake Conversion Kit</Link></h5>
									<p className="added-cart"><i className="fa fa-check-circle" />Successfully added to your Cart</p>
									<div className="btn-wrapper">
									<Link to="/cart" className="theme-btn-1 btn btn-effect-1">View Cart</Link>
									<Link to="/checkout" className="theme-btn-2 btn btn-effect-2">Checkout</Link>
									</div>
								</div>
								{/* additional-info */}
								<div className="additional-info d-none">
									<p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
									<div className="payment-method">
									<img src={publicUrl+"assets/img/icons/payment.png"} alt="#" />
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
						</div>
					</div>
					</div>
				</div>
			</div>


			</div>

	)
}

export default ShopGridV1