import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="google-map mb-120">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.590915400065!2d90.4410439153849!3d23.761963194250573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9e512db7867%3A0x112f3ba22357cc04!2sAlluvium%20Landmark%20Limited!5e0!3m2!1sen!2sbd!4v1647751929001!5m2!1sen!2sbd"
            width="100%" height="100%" loading="lazy"/>
        </div>
        }
}

export default Map