import React, {useState} from 'react';
import { Link } from 'react-router-dom';

const SearchForm = (param) => {
	const [areas, setAreas] = useState([]);
	const [status, setStatus] = useState([]);
	const [types, setTypes] = useState([]);
	const [disabled, setDisabled] = useState('disabled');

	return (
		<>
			<div className="ltn__car-dealer-form-area mt--65 mt-120 pb-115---">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="ltn__car-dealer-form-tab">
								<div className="ltn__tab-menu  text-uppercase d-none">
									<div className="nav">
										<a className="active show" data-bs-toggle="tab" href="#ltn__form_tab_1_1"><i className="fas fa-car" />Find A Car</a>
										<a data-bs-toggle="tab" href="#ltn__form_tab_1_2" ><i className="far fa-user" />Get a Dealer</a>
									</div>
								</div>
								<div className="tab-content bg-white box-shadow-1 position-relative pb-10">
									<div className="tab-pane fade active show" id="ltn__form_tab_1_1">
										<div className="car-dealer-form-inner">
											<form action="#">
												<div className='row'>
													<div className="col-lg-3 col-md-6">
														<select className="dropdownDiv" onChange={event => {setAreas(event.target.value); setDisabled('')}}>
															<option value="">Choose Area</option>
															{
																param.areas?.map(area=>(
																	<option value={area}>{area}</option>
																))
															}
														</select>
													</div>
													<div className="col-lg-3 col-md-6">
														<select className="dropdownDiv" required={true} onChange={event => {setStatus(event.target.value); setDisabled('')}}>
															<option value="">Property Status</option>
															{
																param.status?.map(stat=>(
																	<option value={stat.split(" ")[0].toLowerCase()+'-'+stat.split(" ")[1].toLowerCase()}>{stat}</option>
																))
															}
														</select>
													</div>
													<div className="col-lg-3 col-md-6">
														<select className="dropdownDiv" required={true} onChange={event => {setTypes(event.target.value); setDisabled('')}}>
															<option value="">Property Type</option>
															{
																param.types?.map(type=>(
																	<option value={type.id}>{type.name}</option>
																))
															}
														</select>
													</div>
													<div className="col-lg-3 col-md-6">
														<div className="btn-wrapper text-center mt-0 go-top">
															{/* <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
															<Link 
																to={{
																	pathname: '/listings',
																	search: `?purpose=${status}&property-type=${types}&area=${areas}`
																}}
																className={"btn theme-btn-1 btn-effect-1 text-uppercase "+disabled}>Find Now</Link>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SearchForm;