import React, { Component } from 'react';
class ContactInfo extends Component {

    render() {
	let publicUrl = process.env.PUBLIC_URL+'/';
	let contact = this.props.contact_infos;
    return <div className="ltn__contact-address-area mb-90">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
								<div className="ltn__contact-address-icon">
								<img src={publicUrl+"assets/img/icons/10.png"} alt="Icon Image" />
								</div>
								<h3>Email Address</h3>
								<p>{contact.email1? contact.email1:"info@example.com"}<br />
									{contact.email2? contact.email2:"info@example.com"}</p>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
								<div className="ltn__contact-address-icon">
								<img src={publicUrl+"assets/img/icons/11.png"} alt="Icon Image" />
								</div>
								<h3>Phone Number</h3>
								<p>+88{contact.phone1? contact.phone1:"01322902980"} <br /> +88{contact.phone2? contact.phone2:"01322902980"}</p>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
								<div className="ltn__contact-address-icon">
								<img src={publicUrl+"assets/img/icons/12.png"} alt="Icon Image" />
								</div>
								<h3>Office Address</h3>
								<p>{contact.full_address ? contact.full_address:"example, example-1234"}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
        }
}

export default ContactInfo