import React, {useState} from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AboutV4 from './section-components/about-v4';
import Features from './section-components/features-v1';
import Team from './section-components/team-v1';
import Footer from './global-components/footer';
import Axios from "axios";

const About_v1 = () => {
	const [services, setServices] = useState([]);
	const [abouts, setAbouts] = useState([]);
	const [teams, setTeams] = useState([]);
	const [contact_infos, setContactInfos] = useState([]);
	const [property_types, setPropertyTypes] = useState([]);
	const [properties_data, setPropertiesData] = useState([]);

    React.useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		Axios.get(window.api_url+'/api/global')
		.then(res => {
            const property=[];
            const properties_data=[];
            let response = res.data.data.global_data;
            const abouts = response.about;
            const teams = response.team;
            const contact_infos = response.contact;
            const properties = response.properties;
            const property_types = response.property_types;
            const services = response.services;
            properties.map(pro=>{
                if(!property.includes(pro.listing_purpose)){
                    property.push(pro.listing_purpose)
                    properties_data.push(pro)
                }
            })
            setServices(services);
            setContactInfos(contact_infos);
            setPropertyTypes(property_types);
            setPropertiesData(properties_data);
            setAbouts(abouts);
            setTeams(teams);
		});
	}

    return <div>
        <Navbar contact_infos={contact_infos} property_types={property_types} properties_data={properties_data}/>
        <PageHeader headertitle="About Us" />
        <AboutV4 abouts={abouts}/>
        <Features customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" services={services}/>
        <Team teams={teams}/>
        <Footer property_types={property_types} properties_data={properties_data} contact_infos={contact_infos}/>
    </div>
}

export default About_v1

