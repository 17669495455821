import React, {useState} from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v1';
import ProductDetails from './shop-components/shop-details';
import Footer from './global-components/footer';
import { useParams  } from 'react-router-dom';
import Axios from "axios";

const Product_Details = () => {
    const propertyId = useParams().id;
    const [property, setProperty] = useState([]);
    const [property_types, setPropertyTypes] = useState([]);
    const [contact_infos, setContactInfos] = useState([]);
    const [properties_data, setPropertiesData] = useState([]);

    React.useEffect(() => {
        getData();
        fetch(window.api_url+'/api/property/'+propertyId)
            .then(results => results.json())
            .then(res => {
                const property = res.data.property;
                console.log(property);
                setProperty(property);
            });
    }, []);

    const getData = () => {
        Axios.get(window.api_url+'/api/global')
            .then(res => {
                const property=[];
                const properties_data=[];
                let response = res.data.data.global_data;
                const contact_infos = response.contact;
                const properties = response.properties;
                const property_types = response.property_types;
                properties.map(pro=>{
                    if(!property.includes(pro.listing_purpose)){
                        property.push(pro.listing_purpose)
                        properties_data.push(pro)
                    }
                })
                setContactInfos(contact_infos);
                setPropertiesData(properties_data);
                setPropertyTypes(property_types);
            });
    }

    return <div>
        <Navbar contact_infos={contact_infos} property_types={property_types} properties_data={properties_data}/>
        <PageHeader headertitle="Listing Details" customclass="mb-0" />
        <ProductSlider property={property} />
        <ProductDetails property={property} contact_infos={contact_infos} />
        <Footer property_types={property_types} properties_data={properties_data} contact_infos={contact_infos}/>
    </div>
}

export default Product_Details
