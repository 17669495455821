import React, {useState} from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ShopGrid from './shop-components/shop-grid-v1';
import Footer from './global-components/footer';
import { useParams, useLocation } from 'react-router-dom';
import Axios from "axios";

const ShopGrid_V1 = () => {
    let propertyType = '';
    let purpose = '';
    let newPurpose = '';
    let area = '';
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.get('purpose')){
        purpose = searchParams.get('purpose');
        const splitText = purpose.split('-');
        const capitalized1 = splitText[0].charAt(0).toUpperCase()+ splitText[0].slice(1);
        const capitalized2 = splitText[1].charAt(0).toUpperCase()+ splitText[1].slice(1);
        newPurpose = capitalized1+" "+capitalized2;
    }
    if(searchParams.get('property-type')){
        propertyType = searchParams.get('property-type');
    }
    if(searchParams.get('area')){
        area = searchParams.get('area');
    }
    const [contact_infos, setContactInfos] = useState([]);
	const [property_types, setPropertyTypes] = useState([]);
	const [properties_data, setPropertiesData] = useState([]);
	const [total_properties, setTotalProperties] = useState([]);

    React.useEffect(() => {
		getGlobalData();
	}, []);

	const getGlobalData = () => {
		Axios.get(window.api_url+'/api/global')
		.then(res => {
            const property=[];
            const properties_data=[];
            let response = res.data.data.global_data;
            const contact_infos = response.contact;
            const properties = response.properties;
            const property_types = response.property_types;
            properties.map(pro=>{
                if(!property.includes(pro.listing_purpose)){
                    property.push(pro.listing_purpose)
                    properties_data.push(pro)
                }
            })
            setContactInfos(contact_infos);
            setPropertyTypes(property_types);
            setPropertiesData(properties_data);
            setTotalProperties(properties);
		});
	}

    return <div>
        <Navbar contact_infos={contact_infos} property_types={property_types} properties_data={properties_data}/>
        <PageHeader headertitle="Property Listing" />
        <ShopGrid totalProperties={total_properties} purpose={newPurpose} type={propertyType} area={area} contact={contact_infos}/>
        <Footer property_types={property_types} properties_data={properties_data} contact_infos={contact_infos}/>
    </div>
}

export default ShopGrid_V1

