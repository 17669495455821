import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class AboutV4 extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/';
		let about = this.props.abouts;

    return  <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={window.api_url+"/"+about.about_image} alt="About Us Image" />
						</div>
						</div>
						<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
							<h1 className="section-title">{about.heading}</h1>
							<p>{about.description}</p>
							</div>
							<ul className="ltn__list-item-half clearfix">
							<li>
								<i className="flaticon-home-2" />
								{about.feature1}
							</li>
							<li>
								<i className="flaticon-mountain" />
								{about.feature2}
							</li>
							<li>
								<i className="flaticon-heart" />
								{about.feature3}
							</li>
							<li>
								<i className="flaticon-secure" />
								{about.feature4}
							</li>
							</ul>
							<div className="ltn__callout bg-overlay-theme-05  mt-30">
							<p>"{about.quotes}" </p>
							</div>
							<div className="btn-wrapper animated">
							<Link to="/contact" className="theme-btn-1 btn btn-effect-1">GET IN TOUCH</Link>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
        }
}

export default AboutV4