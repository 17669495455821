import React, { Component} from 'react';
import { Link } from 'react-router-dom';

class FeaturesV1 extends Component {
    render() {
		let publicUrl = process.env.PUBLIC_URL+'/';
		let customClass = this.props.customClass ? this.props.customClass :''

    return <div>
				<div className={ customClass } >
					<div className="container">
						<div className="row">
						<div className="col-lg-12">
							<div className="section-title-area ltn__section-title-2--- text-center">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Services</h6>
							<h1 className="section-title">Our Main Focus</h1>
							</div>
						</div>
						</div>
						<div className="row ltn__custom-gutter--- justify-content-center go-top">
							{
								this.props.services.length===0?(
									<div className="loaderDiv">
										<img className='loaderGif' src={publicUrl+'assets/img/loader.gif'}  alt="loader"/>
									</div>
								):
								this.props.services?.map((service, index) =>(
									<div className="col-lg-4 col-sm-6 col-12" key={index}>
										<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
										<div className="ltn__feature-icon">
											<img src={window.api_url+"/"+service.logo} alt="#" />
										</div>
										<div className="ltn__feature-info">
											<h3><Link to="/contact">{service.service_name}</Link></h3>
											<p>{service.description}</p>
											<Link className="ltn__service-btn" to="/contact">Find A Home <i className="flaticon-right-arrow" /></Link>
										</div>
										</div>
									</div>
								))
							}
						</div>
					</div>
				</div>
			</div>
        }
}

export default FeaturesV1