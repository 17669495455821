import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Slider from "react-slick";

class ProductSliderV1 extends Component {

    render() {
		let publicUrl = process.env.PUBLIC_URL+'/';

		function SampleNextArrow(props) {
			const { onClick } = props;
			return (
				<a className="slick-next slick-arrow" onClick={onClick}>
					<i className="fas fa-arrow-right"/>
				</a>
			);
		}
		  
		function SamplePrevArrow(props) {
			const { onClick } = props;
			return (
				<a className="slick-prev slick-arrow" onClick={onClick}>
					<i className="fas fa-arrow-left"/>
				</a>
			);
		}

		const settings = {
			className: "center",
			centerMode: true,
			infinite: true,
			centerPadding: "400px",
			slidesToShow: 1,
			speed: 300,
			nextArrow: <SampleNextArrow />,
      		prevArrow: <SamplePrevArrow />,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						className: "center",
						centerMode: true,
						infinite: true,
						centerPadding: "400px",
						slidesToShow: 1,
						speed: 300,
						nextArrow: <SampleNextArrow />,
						prevArrow: <SamplePrevArrow />,
					}
				},
				{
					breakpoint: 600,
					settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
					slidesToShow: 1,
					slidesToScroll: 1
					}
				}
			]
		  };

		  const settingsMobile = {
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			pauseOnHover: true,
			arrows: false,
			}

    return (
			<div className="ltn__img-slider-area mb-90">
				<div className="container-fluid">
					<div className="row slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
						<Slider {...settings} className="col-lg-12">
							{
								this.props.property.images?.map((img, ind) =>(
									<div className="ltn__img-slide-item-4" key={ind}>
										<img src={window.api_url+"/upload_files/property/"+img.src} alt="slider" />
									</div>
								))
							}
						</Slider>
					</div>
				</div>
				<div className='mobileSlider'>
					<Slider {...settingsMobile} className="col-sm-12">
								{
							this.props.property.images?.map((img, imgInd) =>(
								<div key={imgInd} style={{marginBottom:35}}>
									<a href={window.api_url+"/upload_files/property/"+img.src} data-rel="lightcase:myCollection">
										<img src={window.api_url+"/upload_files/property/"+img.src} alt="slider" />
									</a>
								</div>
							))
						}
					</Slider>
				</div>
			</div>
			)
        }
}

export default ProductSliderV1