import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Copyright from './copyright';

class Footer_v1 extends Component {
    componentDidMount() {
    	const $ = window.$;
        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);

         $('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

				$(window).scrollTop(0);

			setTimeout(function(){
			    	$(".quarter-overlay").fadeOut(300);
				}, 800);

        });


		$(document).on('click','.theme-btn-1 ', function(){ 
            $( 'div' ).removeClass( 'modal-backdrop' );
            $( 'div' ).removeClass( 'show' );
            $( 'div' ).removeClass( 'fade' );
			$('body').attr("style", "");
        });
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let contact = this.props.contact_infos;
        return (
				<footer className="ltn__footer-area  ">
				  <div className="footer-top-area  section-bg-2 plr--5">
				    <div className="container-fluid">
				      <div className="row">
				        <div className="col-xl-4 col-md-4 col-sm-4 col-12">
							{
								<div className="footer-widget footer-about-widget">
									<div className="footer-logo">
									  <div className="site-logo">
										<img src={publicUrl+"assets/img/logo.png"} alt="Logo" />
									  </div>
									</div>
									<div className="footer-address">
									  <ul>
										<li>
										  <div className="footer-address-icon">
											<i className="icon-placeholder" />
										  </div>
										  <div className="footer-address-info">
											<p>{contact.full_address ? contact.full_address:"example, example-1234"}</p>
										  </div>
										</li>
										<li>
										  <div className="footer-address-icon">
											<i className="icon-call" />
										  </div>
										  <div className="footer-address-info">
											<p><a href="tel:+0123-456789">+88{contact.phone1? contact.phone1:"01322902980"}</a></p>
										  </div>
										</li>
										<li>
										  <div className="footer-address-icon">
											<i className="icon-mail" />
										  </div>
										  <div className="footer-address-info">
											<p><a href="mailto:example@example.com">{contact.email1? contact.email1:"info@example.com"}</a></p>
										  </div>
										</li>
									  </ul>
									</div>
									<div className="ltn__social-media mt-20">
										<div className="ltn__social-media">
											<ul>
												<li><a href={`${contact.facebook_link?contact.facebook_link:"https://www.facebook.com"}`} target="_blank" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
												<li><a href={`${contact.twitter_link?contact.twitter_link:"https://www.twitter.com"}`} target="_blank" title="Twitter"><i className="fab fa-twitter" /></a></li>
												<li><a href={`${contact.instagram_link?contact.instagram_link:"https://www.instagram.com"}`} target="_blank" title="Instagram"><i className="fab fa-instagram" /></a></li>
												<li><a href={`${contact.linkedin_link?contact.linkedin_link:"https://www.linkedin.com"}`} target="_blank" title="Lindedin"><i className="fab fa-linkedin" /></a></li>
											</ul>
										</div>
									</div>
								</div>
							}
				        </div>
				        <div className="col-xl-2 col-md-4 col-sm-4 col-12">
							<div className="footer-widget footer-menu-widget clearfix">
								<h4 className="footer-title">Company</h4>
								<div className="footer-menu go-top">
									<ul>
										<li><Link to="/about">About</Link></li>
										<li><Link to="/faq">FAQ</Link></li>
										<li><Link to="/contact">Contact us</Link></li>
										<li><Link to="/about">Terms &amp; Conditions</Link></li>
										<li><Link to="/about">Privacy &amp; Policy</Link></li>
									</ul>
								</div>
							</div>
				        </div>
						{
								this.props.properties_data?.map((property, index) =>(
									<div className="col-xl-2 col-md-4 col-sm-4 col-12" key={index}>
										<div className="footer-widget footer-menu-widget clearfix">
											<h4 className="footer-title">{property.listing_purpose==='For Sale'?'Buy':property.listing_purpose==='For Rent'?'Rent':'Lease'}</h4>
											<div className="footer-menu go-top">
												<ul>
													<li>
														<Link 
															to={{
																pathname: '/listings',
																search: `?purpose=${property.listing_purpose.split(" ")[0].toLowerCase()+'-'+property.listing_purpose.split(" ")[1].toLowerCase()}&property-type=${property.property_type_id}`
															}}
														>	{property.property_type+" "+property.listing_purpose}
														</Link>
													</li>
												</ul>
											</div>
										</div>
				        			</div>
								))
							}
					</div>
				    </div>
				  </div>
				  <Copyright />
				</footer>
        )
    }
}


export default Footer_v1