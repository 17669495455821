import React, {useState} from 'react';
import Axios from "axios";

const ContactForm = () => {
	const [disabled, setDisabled] = useState('');
	const [textColor, setTextColor] = useState('');
	const [alert, setAlert] = useState('');
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');
	let publicUrl = process.env.PUBLIC_URL+'/';
	const sendMessage = () => {
		setLoading(true)
		setDisabled("disabled");
		if(name=="" || email=="" || phone=="" || message==""){
			setTextColor('text-warning')
			setAlert("Please fill all the fields");
			setDisabled("")
			setLoading(false)
		}
		else {
			Axios.get(window.api_url+`/api/send-mail?listing_id=${null}&name=${name}&email=${email}&phone=${phone}&message=${message}`)
			.then(res => {
				if(res.data.success==true){
					setDisabled("")
					setLoading(false)
					setTextColor('text-success')
					setAlert("Message has been send successfully!");
					setName("");
					setEmail("");
					setMessage("");
					setPhone("");
				}
				else {
					setLoading(false)
					setDisabled("")
					setTextColor('text-danger')
					setAlert("Message not sent. Something went wrong!")
					setName("");
					setEmail("");
					setMessage("");
					setPhone("");
				}
			}).catch(e=>{
				setLoading(false)
				setDisabled("")
				setTextColor('text-danger')
				setAlert("Message not sent. Something went wrong!")
				setName("");
				setEmail("");
				setMessage("");
				setPhone("");
			});
		}
	}
	setTimeout(() => {
		setTextColor('alert-hidden')
	}, 2000);
	return (
		<>
			<div className="ltn__contact-message-area mb-120 mb--100">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="ltn__form-box contact-form-box box-shadow white-bg">
								<h4 className="title-2">Get A Quote</h4>
									<div className="row">
										<div className="col-md-4">
											<div className="input-item input-item-name ltn__custom-icon">
												<input type="text"
													   value={name}
													   name="name"
													   placeholder="Enter your name"
													   onChange={(e)=>{setName(e.target.value)}}
												/>
											</div>
										</div>
										<div className="col-md-4">
											<div className="input-item input-item-email ltn__custom-icon">
												<input type="email"
													   name="email"
													   value={email}
													   placeholder="Enter email address"
													   onChange={(e)=>{setEmail(e.target.value)}}
												/>
											</div>
										</div>
										<div className="col-md-4">
											<div className="input-item input-item-phone ltn__custom-icon">
												<input type="text"
													   name="phone"
													   value={phone}
													   placeholder="Enter phone number"
													   onChange={(e)=>{setPhone(e.target.value)}}
												/>
											</div>
										</div>
									</div>
									<div className="input-item input-item-textarea ltn__custom-icon">
										<textarea name="message"
												  placeholder="Enter message"
												  value={message}
												  onChange={(e)=>{setMessage(e.target.value)}}
										/>
									</div>
									{/*<p><label className="input-info-save mb-0"><input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.</label></p>*/}
									<div className="btn-wrapper mt-0">
										<button className={"btn theme-btn-1 btn-effect-1 text-uppercase "+disabled}
												type="button" onClick={sendMessage}>
											{loading?(
												<div className="spinner-border" role="status">
													<span className="sr-only"/>
												</div>
											):"Send Message"}
										</button>
									</div>
									<p style={{fontSize: 22, fontWeight: 'bold'}} className={"form-messege mb-0 mt-20 "+textColor}>{alert}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactForm;